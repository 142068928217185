import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useDispatch } from "react-redux";
import axios from "axios";
import { stripeCheckoutModalShow } from "../../redux/cartSlice";
import StripeCheckoutModal from "../modal/StripeCheckout_Modal";
import { useActiveAccount } from "thirdweb/react";

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUB_KEY);

export const StripeCheckout = ({
  checkout,
  setCheckout,
  setCheckoutLoading,
}) => {
  const dispatch = useDispatch();

  const  user  = useActiveAccount();

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    setCheckoutLoading(true);

    axios
      .post("/api/stripe/buy-credits-session", {
        walletAddress: user?.address,
        creditPackageId: checkout,
      })
      .then((response) => {
        setCheckoutLoading(false);
        setClientSecret(response.data.clientSecret);
        dispatch(stripeCheckoutModalShow());
      })
      .catch((error) => {
        setCheckoutLoading(false);
        setCheckout(null);
        console.error("Error fetching products:", error);
      });
  }, []);

  return (
    <div id="checkout">
      {clientSecret && (
        <StripeCheckoutModal setCard={setCheckout}>
          <EmbeddedCheckoutProvider
            stripe={stripePromise}
            options={{ clientSecret }}
          >
            <EmbeddedCheckout />
          </EmbeddedCheckoutProvider>
        </StripeCheckoutModal>
      )}
    </div>
  );
};

export default StripeCheckout;
