import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { stripeCheckoutModalhide } from "../../redux/cartSlice";

const StripeCheckoutModal = ({ setCard, children }) => {

  const checkoutModal = useSelector((state) => state.cart.stripeCheckoutModal);
  const dispatch = useDispatch();



  return (
    <div className="">
      {/* <!-- Wallet Modal --> */}
      <div
        className={checkoutModal ? "block modal fade show p-4 fixed z-[999999]  " : "modal fade hidden"}
      >
        <div className="modal-dialog max-w-lg w-full gradient-box ">
          <div className="modal-content   dark:bg-jacarta-900  overflow-auto m-0  rounded-md opacity-100 right-0">

            {/* <!-- Body --> */}
            <div className="modal-body text-center">
              <button

                type="button"
                className="btn-close absolute top-2 right-2"
                onClick={() => { dispatch(stripeCheckoutModalhide()); setCard(false) }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-900 h-6 w-6 "
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div >

  );
};

export default StripeCheckoutModal;


